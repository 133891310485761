import {
  Button,
  SelectDropdown,
  IOption,
  TextField,
  Checkbox,
} from "@patientmpower/spiro";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
  IQuestionInfoForConditionalJump,
  ISocreStrategyOption,
  ISurvey,
  ISurveyQuestion,
  ISurveySection,
} from "../../../../../@types/Surveys";
import { FixedTranslationsLanguageCodes } from "../../../../../@types/Translations";
import { BlackPlus } from "../../../../../assets/icons/BlackPlus";
import { Close } from "../../../../../assets/icons/Close";
import { Pencil } from "../../../../../assets/icons/Pencil";
import { CustomIconButton } from "../../../../../components/CustomIconButton";
import {
  ScoreStrategiesWithFixedDomains,
  KbildDomains,
  LpfSymptomsDomains,
  LpfImpactDomains,
} from "../../../../../constants/surveys";
import { useModal } from "../../../../../hooks/useModal";
import { PageTitle } from "../../../../../layout/components/PageTitle";
import { surveysService } from "../../../../../services/surveysService";
import { translationService } from "../../../../../services/translationsService";
import { parseSurveyTranslationResponseToDisplay } from "../../../../../utils/translationsUtils";
import { CancelButton } from "../../../../Settings/components/SecurityModal/SecurityModal.styles";
import { AddQuestionModal } from "./Components/AddQuestionModal";
import { DateQuestionDisplay } from "./Components/DateQuestionDisplay";
import { EditSurveyInfoModal } from "./Components/EditSurveyInfoModal";
import { FiveQuestionsAboutSupOxDisplay } from "./Components/FiveQuestionsAboutSupOxDisplay";
import { InformativeQuestionDisplay } from "./Components/InformativeQuestionDisplay";
import { MultiSelectQuestionDisplay } from "./Components/MultiSelectQuestionDisplay";
import { SingleSelectQuestionDisplay } from "./Components/SingleSelectQuestionDisplay";
import { SliderQuestionDisplay } from "./Components/SliderQuestionDisplay";
import { TextQuestionDisplay } from "./Components/TextQuestionDisplay";
import {
  FormContainer,
  FormInfoContainer,
  HandleFormContainer,
  QuestionsContainer,
  Question,
  SeparationLine,
  FormTitle,
  SectionDropdownContainer,
  AddSectionContainer,
  SectionTitle,
  ScoreContainer,
  ScoringToogleContainer,
  DomainContainer,
  AddDomainContainer,
  DomainList,
  CloseContainer,
  ListItem,
  spinCss,
} from "./SurveyBuilder.styles";
import { vasConfig } from "./VasConfig";

export function SurveyBuilder() {
  const { openModal, closeModal } = useModal();
  const [surveyTitle, setSurveyTitle] = useState<string>(
    "Insert the survey title..."
  );
  const [surveyDescription, setSurveyDescription] = useState<string>(
    "Insert the survey description here..."
  );
  const [sectionSelected, setSectionSelected] = useState<string>("Section 1");
  const [sections, setSections] = useState<ISurveySection[]>([
    {
      order: 1,
      title: "Insert section title here...",
      questions: [],
    },
  ]);

  const [editSectionTitle, setEditSectionTitle] = useState<boolean>(false);
  const [newSectionTitle, setNewSectionTitle] = useState<string>("");
  const [questionListforConditional, setQuestionListforConditional] = useState<
    IQuestionInfoForConditionalJump[]
  >([]);

  const [hasScoring, setHasScoring] = useState(false);
  const [scoreStrategiesOptions, setScoreStrategiesOptions] = useState<
    IOption[]
  >([]);
  const [scoreStrategy, setScoreStrategy] = useState("");
  const [domainToAdd, setDomainToAdd] = useState<string>("");
  const [domains, setDomains] = useState<string[]>([]);
  const [domainError, setDomainError] = useState<string>("");

  const [loadingTranslations, setLoadingTranslations] =
    useState<boolean>(false);
  const [loadingSurvey, setLoadingSurvey] = useState<boolean>(false);
  const [loadingDomains, setLoadingDomains] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();
  const surveyId = location.state;

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage();
    if (surveyId !== null) {
      setLoadingTranslations(true);
      setLoadingSurvey(true);
      (async () => {
        const survey: ISurvey = (await surveysService.getSurveyById(surveyId))
          .data;
        setSurveyTitle(survey.name);
        setSurveyDescription(survey.description ?? "");
        setSections(survey.sections);
        setHasScoring(
          survey.scoreStrategy !== "" && survey.scoreStrategy !== undefined
        );
        setScoreStrategy(survey.scoreStrategy ?? "");

        const savedDomains = new Set<string>();

        survey.sections.forEach((section) => {
          section.questions.forEach((question) => {
            if (question.domain === undefined) return;

            savedDomains.add(question.domain);
          });
        });

        setDomains(Array.from(savedDomains));
        setLoadingSurvey(false);
      })();
    }

    (async () => {
      const scoreStrategiesOptions: ISocreStrategyOption[] = (
        await surveysService.getScoreStrategiesOptions()
      ).data;

      const strategyOptionsToAdd: IOption[] = scoreStrategiesOptions.map(
        (scoreStrategy) => {
          return {
            value: scoreStrategy.type,
            label: scoreStrategy.name,
            key: scoreStrategy.type,
          };
        }
      );

      setScoreStrategiesOptions(strategyOptionsToAdd);
      setLoadingDomains(false);
    })();

    translationService
      .getSurveysFixedTranslationsByLanguageCode(
        i18n.language as FixedTranslationsLanguageCodes
      )
      .then((translations) => {
        if (!translations.data) return;
        const resourcesToAdd = parseSurveyTranslationResponseToDisplay(
          translations.data
        );
        i18n.addResourceBundle(i18n.language, "translation", resourcesToAdd);
        setLoadingTranslations(false);
      });
  }, []);

  const updateSectionsList = (
    question: ISurveyQuestion,
    questionIndex: number,
    sectionIndex: number
  ) => {
    const updatedSections = [...sections];

    if (questionIndex !== -1) {
      updatedSections[sectionIndex].questions[questionIndex] = question;
    } else {
      updatedSections[sectionIndex].questions.push(question);
    }
    setSections(updatedSections);
  };

  const updateQuestionIdList = () => {
    const updatedQuestionIdList: IQuestionInfoForConditionalJump[] = [];
    sections.forEach((section) => {
      section.questions.forEach((question) => {
        updatedQuestionIdList.push({
          id: question.id,
          question: question.text,
        });
      });
    });
    setQuestionListforConditional(updatedQuestionIdList);
  };

  useEffect(() => {
    updateQuestionIdList();
  }, [sections]);

  const sectionDropdownOptions = (): IOption[] => {
    const options: IOption[] = [];

    sections.forEach((section, index) => {
      options.push({
        label: `Section ${index + 1}`,
        value: `Section ${index + 1}`,
        key: uuidv4(),
      });
    });
    return options;
  };

  const handleRemoveQuestion = (
    questionIndex: number,
    sectionIndex: number
  ) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions.splice(questionIndex, 1);
    setSections(updatedSections);
  };

  const handleEditQuestion = (
    question: ISurveyQuestion,
    questionIndex: number,
    sectionIndex: number
  ) => {
    openModal(
      <AddQuestionModal
        onClose={closeModal}
        updateSectionsList={updateSectionsList}
        question={question}
        questionIndex={questionIndex}
        sectionIndex={sectionIndex}
        questionListforConditional={questionListforConditional}
        scoreStrategy={scoreStrategy}
        domains={domains}
      />,
      {
        width: "70vw",
        maxHeight: "95vh",
      }
    );
  };

  const handleAddQuestion = (sectionIndex: number) => {
    openModal(
      <AddQuestionModal
        onClose={closeModal}
        updateSectionsList={updateSectionsList}
        sectionIndex={sectionIndex}
        questionListforConditional={questionListforConditional}
        scoreStrategy={scoreStrategy}
        domains={domains}
      />,
      {
        width: "70vw",
        maxHeight: "95vh",
      }
    );
  };

  const handleEditSurveyInfoClick = () => {
    openModal(
      <EditSurveyInfoModal
        onClose={closeModal}
        onSurveyDescriptionChange={setSurveyDescription}
        onSurveyTitleChange={setSurveyTitle}
        title={surveyTitle}
        description={surveyDescription}
      />,
      {
        width: "55vw",
        maxHeight: "95vh",
      }
    );
  };

  const parseSectionsToSubmit = () => {
    const updatedSections = [...sections];
    let questionOrder = 0;
    sections.forEach((section, sectionIndex) => {
      updatedSections[sectionIndex].title = section.title.trim();

      section.questions.forEach((question, questionIndex) => {
        questionOrder += 1;
        updatedSections[sectionIndex].questions[questionIndex].order =
          questionOrder;
        updatedSections[sectionIndex].questions[questionIndex].text =
          question.text.trim();
        updatedSections[sectionIndex].questions[questionIndex].description =
          question.description.trim();
      });
    });

    return updatedSections;
  };

  const handleSubmit = async () => {
    const updatedSections = parseSectionsToSubmit();

    if (surveyId === null) {
      const finalSurvey: ISurvey = {
        name: surveyTitle.trim(),
        description: surveyDescription.trim(),
        sections: updatedSections,
        scoreStrategy,
      };

      await surveysService.addSurvey(finalSurvey);
    } else {
      const finalSurvey: ISurvey = {
        id: surveyId,
        name: surveyTitle.trim(),
        description: surveyDescription.trim(),
        sections: updatedSections,
        scoreStrategy,
      };

      await surveysService.editSurvey(finalSurvey);
    }

    navigate("/surveys");
  };

  const handleCancelButton = () => {
    navigate("/surveys");
  };

  const handleAddSection = () => {
    const updatedSections = [...sections];
    updatedSections.push({
      order: sections[sections.length - 1].order + 1,
      title: "Insert section title here...",
      questions: [],
    });
    setSections(updatedSections);
    setSectionSelected(`Section ${updatedSections.length}`);
  };

  const handleNewSectiontitleSubmit = (sectionIndex: number) => {
    const updatedSections = [...sections];
    const newTitle = newSectionTitle;
    updatedSections[sectionIndex].title = newTitle;
    setSections(updatedSections);
    setEditSectionTitle(false);
    setNewSectionTitle("");
  };

  const handleRemoveSection = (sectionIndex: number) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    setSections(updatedSections);
    setSectionSelected(`Section 1`);
  };

  const handleAddDomain = () => {
    if (domainToAdd === "") return;

    const domainAlreadyExists = domains.findIndex(
      (domain) => domain === domainToAdd
    );

    if (domainAlreadyExists > -1) {
      setDomainError("Domain already exists.");
      return;
    }

    setDomainError("");
    setDomains((prevState) => [...prevState, domainToAdd]);
    setDomainToAdd("");
  };

  const handleRemoveDomain = (domainIndex: number) => {
    const updatedDomains = [...domains];
    updatedDomains.splice(domainIndex, 1);
    setDomains(updatedDomains);
  };

  return loadingTranslations || loadingDomains || loadingSurvey ? (
    <Spin className={spinCss()} fullscreen size="large" />
  ) : (
    <FormContainer>
      <FormInfoContainer style={{ marginBottom: "30px" }}>
        <FormTitle>{surveyTitle}</FormTitle>
        <p>{surveyDescription}</p>
        <CustomIconButton
          onClick={handleEditSurveyInfoClick}
          style={{
            position: "absolute",
            top: "3px",
            right: "3px",
          }}
        >
          <Pencil />
        </CustomIconButton>
      </FormInfoContainer>

      <SeparationLine />

      <ScoreContainer>
        <ScoringToogleContainer>
          <Checkbox
            label=""
            checked={hasScoring}
            onChange={(checked) => setHasScoring(checked)}
          />
          <p>Include Scoring</p>
        </ScoringToogleContainer>

        {hasScoring ? (
          <SelectDropdown
            placeholder="Select score strategy"
            options={scoreStrategiesOptions}
            width={300}
            value={scoreStrategy !== "" ? scoreStrategy : undefined}
            onValueChange={(value) => {
              if (value === "KBILD") {
                setDomains(KbildDomains);
              } else if (value === "LpfImpactScore") {
                setDomains(LpfImpactDomains);
              } else if (value === "LpfSymptomsScore") {
                setDomains(LpfSymptomsDomains);
              } else {
                setDomains([]);
              }
              setScoreStrategy(value as string);
            }}
          />
        ) : null}
      </ScoreContainer>

      {hasScoring ? (
        <DomainContainer>
          {scoreStrategy.includes("Domain") || scoreStrategy === "TotalSum" ? (
            <AddDomainContainer>
              <TextField
                label=""
                placeholder="Please enter a domain"
                value={domainToAdd}
                onChange={(e) => {
                  setDomainToAdd(e.target.value);
                  setDomainError("");
                }}
                backgroudColor="white"
                errorMessage={domainError}
              />
              <Button
                type="button"
                size="small"
                label="Add domain"
                onClick={handleAddDomain}
              />
            </AddDomainContainer>
          ) : null}

          {domains.length > 0 ? (
            <DomainList>
              <p style={{ fontWeight: 600, fontSize: 15 }}>Domains</p>
              {domains?.map((domain, index) => {
                return (
                  <ListItem key={uuidv4()}>
                    &#8226; {domain}
                    <CloseContainer
                      disabled={ScoreStrategiesWithFixedDomains.includes(
                        scoreStrategy
                      )}
                      onClick={() => handleRemoveDomain(index)}
                    >
                      <Close />
                    </CloseContainer>
                  </ListItem>
                );
              })}
            </DomainList>
          ) : null}
        </DomainContainer>
      ) : null}

      <QuestionsContainer>
        <SectionDropdownContainer>
          <SelectDropdown
            options={sectionDropdownOptions()}
            width={200}
            value={sectionSelected}
            onValueChange={(value: string | string[]) => {
              setSectionSelected(value.toString());
            }}
          />

          <SeparationLine />
          <AddSectionContainer key={uuidv4()}>
            <CustomIconButton
              style={{
                border: "1px solid #DFDFDF",
              }}
              onClick={handleAddSection}
            >
              <BlackPlus />
            </CustomIconButton>
          </AddSectionContainer>
        </SectionDropdownContainer>

        {sections !== undefined &&
          sections.map(
            (section, sectionIndex) =>
              `Section ${sectionIndex + 1}` === sectionSelected && (
                <React.Fragment key={sectionIndex}>
                  <SectionTitle>
                    {editSectionTitle && (
                      <TextField
                        maxLength={50}
                        autofocus
                        label=""
                        name="title"
                        placeholder="title"
                        value={newSectionTitle}
                        onChange={(event) => {
                          setNewSectionTitle(event.target.value.toString());
                        }}
                        backgroudColor="white"
                        onKeyDown={(e) => {
                          if (e.key === "Enter")
                            handleNewSectiontitleSubmit(sectionIndex);
                        }}
                        onBlur={() => {
                          handleNewSectiontitleSubmit(sectionIndex);
                        }}
                      />
                    )}
                    {!editSectionTitle && (
                      <>
                        <PageTitle>{section.title}</PageTitle>
                        <CustomIconButton
                          onClick={() => {
                            setEditSectionTitle(!editSectionTitle);
                            setNewSectionTitle(section.title ?? "");
                          }}
                        >
                          <Pencil />
                        </CustomIconButton>
                        {sections.length > 1 && (
                          <CustomIconButton
                            onClick={() => {
                              handleRemoveSection(sectionIndex);
                            }}
                          >
                            <Close />
                          </CustomIconButton>
                        )}
                      </>
                    )}
                  </SectionTitle>
                  <SeparationLine />
                  {section.questions !== undefined &&
                    section.questions.map((question, questionIndex) => (
                      <React.Fragment key={questionIndex}>
                        <Question>
                          {(question.type === "Text" ||
                            question.type === "MultilineText") && (
                            <TextQuestionDisplay
                              title={question.text}
                              type={question.type}
                              description={question.description}
                            />
                          )}
                          {question.type === "SingleSelect" && (
                            <SingleSelectQuestionDisplay
                              title={question.text}
                              description={question.description}
                              options={question.options}
                            />
                          )}
                          {question.type === "MultiSelect" && (
                            <MultiSelectQuestionDisplay
                              title={question.text}
                              description={question.description}
                              options={question.options}
                            />
                          )}
                          {question.type === "Slider" &&
                            question.attributes && (
                              <SliderQuestionDisplay
                                verticalSliderHeight={
                                  question.attributes?.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value === "Vertical"
                                    ? 400
                                    : 0
                                }
                                title={question.text}
                                description={question.description}
                                options={question.options}
                                step={parseFloat(
                                  question.attributes.find(
                                    (attribute) => attribute?.name === "Step"
                                  )?.value
                                )}
                                orientation={
                                  question.attributes.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value
                                }
                              />
                            )}
                          {question.type === "SliderVas" &&
                            question.attributes && (
                              <SliderQuestionDisplay
                                verticalSliderHeight="300px"
                                title={question.text}
                                description={question.description}
                                options={vasConfig.options}
                                step={parseFloat(
                                  question.attributes.find(
                                    (attribute) => attribute?.name === "Step"
                                  )?.value
                                )}
                                orientation={
                                  question.attributes.find(
                                    (attribute) =>
                                      attribute?.name === "Orientation"
                                  )?.value
                                }
                              />
                            )}
                          {question.type === "Date" && (
                            <DateQuestionDisplay
                              title={question.text}
                              description={question.description}
                            />
                          )}
                          {question.type === "FiveQuestionsAboutSupOx" && (
                            <FiveQuestionsAboutSupOxDisplay />
                          )}
                          {question.type === "Informative" && (
                            <InformativeQuestionDisplay
                              HTMLText={question.text}
                            />
                          )}

                          <CustomIconButton
                            onClick={() => {
                              handleRemoveQuestion(questionIndex, sectionIndex);
                            }}
                            style={{
                              position: "absolute",
                              top: "3px",
                              right: "3px",
                            }}
                          >
                            <Close />
                          </CustomIconButton>

                          <CustomIconButton
                            onClick={() => {
                              handleEditQuestion(
                                question,
                                questionIndex,
                                sectionIndex
                              );
                            }}
                            style={{
                              position: "absolute",
                              top: "3px",
                              right: "40px",
                            }}
                          >
                            <Pencil />
                          </CustomIconButton>
                        </Question>
                        <SeparationLine />
                      </React.Fragment>
                    ))}
                  <div>
                    <CustomIconButton
                      style={{
                        width: "auto",
                        borderRadius: "20px",
                        padding: "5px 10px 5px 10px",
                      }}
                      onClick={() => {
                        handleAddQuestion(sectionIndex);
                      }}
                    >
                      <BlackPlus /> Add question
                    </CustomIconButton>
                  </div>
                </React.Fragment>
              )
          )}
      </QuestionsContainer>

      <HandleFormContainer>
        <CancelButton
          onClick={() => handleCancelButton()}
          style={{ marginLeft: "-30px" }}
        >
          Cancel
        </CancelButton>
        <Button
          onClick={() => handleSubmit()}
          label="Save Survey"
          type="button"
        />
      </HandleFormContainer>
    </FormContainer>
  );
}
